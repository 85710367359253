<template>
    <section>
      <div class="bg-white p-2 md:px-32 md:py-20" style=";">
        <div
          style="box-shadow: 5px 4px 18px #00000029;"
          class="rounded-lg w-full block md:flex py-8 imagen-bg">
          <div class="px-0 md:px-12 w-full my-2 lg:w-5/12">
            <div class="px-2 md:px-4 md:py-12">
              <div class="flex items-center justify-center w-full">
                <div class="w-52 h-52 sm:w-36 sm:h-36 flex-none lg:w-52 lg:h-52 image-fit relative" v-if="persona.imagen">
                  <img
                    alt="No"
                    class="rounded-full border-blue-600 border-2 w-full h-full"
                    :src="env + persona.imagen"
                  />
                </div>
                <div class="w-52 h-52 sm:w-36 sm:h-36 flex-none lg:w-52 lg:h-52 image-fit relative" v-else>
                  <img
                    alt="Nol"
                    class="rounded-full border-blue-600 border-2 w-full h-full"
                    src="../../../../../../../images/user.jpg"
                  />
                </div>
              </div>
              <div class="py-4 px-8 h-6/12 mt-14 lg:mt-0">
                <div class="mb-4">
                  <p class="text-xl font-medium">{{ persona.full_name }}</p>
                </div>
                <div>
                  <div class="flex my-2 font-medium">
                    <i class="pi pi-user mr-4"></i>
                    <p class="font-medium">
                      {{usuario.user_name}}
                    </p>
                  </div>
                  <div class="flex my-2 font-medium">
                    <i class="pi pi-envelope mr-4"></i>
                    <p class="font-medium">
                      {{ persona.email }}
                    </p>
                  </div>
                  <div class="flex my-2 font-medium">
                    <i class="pi pi-phone mr-4"></i>
                    <p class="font-medium">
                      {{ persona.telefono }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider class="hidden md:flex" layout="vertical" />
          <div class="px-2 md:px-12 w-full lg:w-7/12 my-2 flex items-center">
            <div class="px-2 w-full md:px-4 md:py-12">
              <div class="border-b-2 border-blue-800 text-blue-800">
                <p class="font-medium text-lg">Datos del usuario</p>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-3 py-4 mt-2 md:mt-8 gap-2 md:gap-y-8">
                <div>
                  <label class="labelStyle">Grupo</label>
                  <p class="font-medium text-md">{{ grupo.name }}</p>
                </div>
                <div>
                  <label class="labelStyle">ID</label>
                  <p class="font-medium text-md">{{ usuario.id }}</p>
                </div>
                <div>
                  <label class="labelStyle">Localizacion:</label>
                  <p class="font-medium text-md">{{ ubicacion ? ubicacion.nomMpio : '' }} <span v-if="ubicacion">,</span> {{ ubicacion ? ubicacion.nomDpto : '' }}</p>
                </div>
                <div>
                  <label class="labelStyle">Direccion:</label>
                  <p class="font-medium text-md">{{ persona.direccion }}</p>
                </div>
                <div>
                  <label class="labelStyle">Fecha de activación</label>
                  <p class="font-medium text-md">{{dayjs(persona.createdAt).format('YYYY / MM / DD')}}</p>
                </div>
                <div>
                  <label class="labelStyle">Numero de documento:</label>
                  <p class="font-medium text-md">{{ persona.type_document_id }} {{ persona.number_document }}</p>
                </div>
              </div>
              <div>
                <div class="md:flex sm:grid sm:grid-cols-1 gap-2 md:justify-between mt-8">
                  <div class="flex">
                    <!-- <RadioButton  class="mr-4 rounded-full h-5 w-5 flex items-center justify-center" inputId="binary" v-model="usuario.state" :binary="true" /> -->
                    <p class="mr-2">Estado:</p>
                    <p class="font-bold text-blue-600" v-if="usuario.state">Activo</p>
                    <p class="font-bold text-red-600" v-else>Inactivo</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import { computed, ref } from 'vue'
  import usuariosStore from '../../../../../../../stores/usuarios.store'
  import dayjs from 'dayjs'
  export default {
    name: 'informacionVerUsuario',
    setup () {
      // References
      const activeUser = ref()
      const env = process.env.VUE_APP_MICROSERVICE_API_USUARIOS.split('/api')[0]
      // Computed
      const usuario = computed(() => usuariosStore.getters._usuario)
      const persona = computed(() => usuariosStore.getters._usuario_persona)
      const grupo = computed(() => usuariosStore.getters._usuario_grupo)
      const ubicacion = computed(() => usuariosStore.getters._ubicacion)
      return {
        usuario,
        activeUser,
        persona,
        grupo,
        env,
        dayjs,
        ubicacion
      }
    }
  }
  </script>
  <style lang="scss" scoped>
    .labelColor {
      color: #7F7E81 !important;
      font-size: 12px/14px !important;
    }
    ::v-deep(.p-inputtext.p-component) {
      width: 100% !important;
    }
    ::v-deep(.p-checkbox .p-checkbox-box) {
      border-radius: 50%;
    }
    ::v-deep(.p-dropdown-trigger) {
      display: none;
    }
    .labelStyle {
      color: #7F7E81;
      font-size: 0.7rem;
    }
    .imagen-bg {
      background-image: url('../../../../../../../images/fondo_image_profile.png');
      background-position: inherit;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    @media (min-width: 768px) {
      .imagen-bg {
        background-size: 37%;
      }
    }
  </style>
